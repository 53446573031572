import { Cookies } from 'react-cookie';

type CookieSetOptions = {
  readonly path?: string;
  readonly expires?: Date;
  readonly maxAge?: number;
  readonly domain?: string;
  readonly secure?: boolean;
  readonly httpOnly?: boolean;
  readonly sameSite?: boolean | 'none' | 'lax' | 'strict';
  readonly encode?: (value: string) => string;
};

type CookieGetOptions = {
  readonly doNotParse?: boolean;
};

const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;
const SERVICE_DOMAIN = 'steelboso.com';
const DEVELOP_DOMAIN = 'dev.steelboso.com';
const STAGING_DOMAIN = 'staging.steelboso.com';

const getAccessTokenName = (currentDomain?: string) => {
  switch (currentDomain) {
    case SERVICE_DOMAIN:
      return 'ACCESS_TOKEN';
    case DEVELOP_DOMAIN:
      return 'DEV_ACCESS_TOKEN';
    case STAGING_DOMAIN:
      return 'STAGING_ACCESS_TOKEN';
    default:
      return 'ACCESS_TOKEN';
  }
};
const getRefreshTokenName = (currentDomain?: string) => {
  switch (currentDomain) {
    case SERVICE_DOMAIN:
      return 'REFRESH_TOKEN';
    case DEVELOP_DOMAIN:
      return 'DEV_REFRESH_TOKEN';
    case STAGING_DOMAIN:
      return 'STAGING_REFRESH_TOKEN';
    default:
      return 'REFRESH_TOKEN';
  }
};

export const ACCESS_TOKEN = getAccessTokenName(currentDomain);
export const REFRESH_TOKEN = getRefreshTokenName(currentDomain);
export const LANGUAGE = 'LANGUAGE';

const cookies = new Cookies();

export const setCookie = (name: string, value: any, options?: CookieSetOptions) => {
  return cookies.set(name, value, { ...options });
};

export const getCookie = (name: string, options?: CookieGetOptions): string => {
  return cookies.get(name, { ...options });
};

export const removeCookie = (name: string, options?: CookieSetOptions) => {
  return cookies.remove(name, { ...options });
};
